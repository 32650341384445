// const SkeletonLoader = () => (
//   <div
//     style={{ height: "660px" }}
//     className="overflow-hidden position-relative bg-light"
//   >
//     <style>
//       {`
//         @keyframes wave {
//           0% { transform: translateX(-100%); }
//           50% { transform: translateX(100%); }
//           100% { transform: translateX(100%); }
//         }
//         .skeleton-wave::after {
//           content: "";
//           position: absolute;
//           top: 0;
//           right: 0;
//           bottom: 0;
//           left: 0;
//           animation: wave 2s infinite;
//           background-image: linear-gradient(90deg, transparent, rgba(255,255,255,0.4), transparent);
//         }
//       `}
//     </style>
//     <div className="bg-opacity-25 h-100 w-100 bg-secondary skeleton-wave"></div>
//   </div>
// );

const SkeletonLoader = () => (
  <div
    className="w-100 d-flex align-items-center justify-content-center"
    style={{ height: "660px" }}
  >
    <div className="lds-ripple">
      <div></div>
      <div></div>
    </div>
  </div>
);

export default SkeletonLoader;
