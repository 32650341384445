import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

function Time({ data }) {


  const calculateTimeLeft = () => {
    const startDate = data?.data?.startDate ?? "00-00-00"; // Date par défaut valide
    const startDateObj = dayjs(startDate);

    if (!startDateObj.isValid()) {
      console.error("Date invalide : ", startDate);
      return {};
    }

    const difference = startDateObj.valueOf() - dayjs().valueOf();
    // console.log("difference", difference);
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  const isEventPassed = Object.keys(timeLeft).length === 0; // Vérifiez si l'événement est déjà passé

  return (
    <div className="time-area">

      {!isEventPassed && ( // Afficher le tableau uniquement si l'événement n'est pas passé
        <table className="time-table">
          <thead>
            <tr className="table-head">
              <th colSpan={4} className="table-items table-items-head">{timeLeft.days || 0}</th>
              <th></th>
              <th colSpan={4} className="table-items table-items-head">{formatTime(timeLeft.hours || 0)}</th>
              <th></th>
              <th colSpan={4} className="table-items table-items-head">{formatTime(timeLeft.minutes || 0)}</th>
              <th></th>
              <th colSpan={4} className="table-items table-items-head">{formatTime(timeLeft.seconds || 0)}</th>
            </tr>
          </thead>
          <tbody>
            <tr className="days">
              <td colSpan={4} className="table-items" style={{ border: "none" }}>Days</td>
              <td style={{ border: "none" }}></td>
              <td colSpan={4} className="table-items" style={{ border: "none" }}>Hours</td>
              <td style={{ border: "none" }}></td>
              <td colSpan={4} className="table-items" style={{ border: "none" }}>Minutes</td>
              <td style={{ border: "none" }}></td>
              <td colSpan={4} className="table-items" style={{ border: "none" }}>Seconds</td>
            </tr>
          </tbody>
        </table>
      )}
      {/* {isEventPassed && <p>L'événement est déjà passé.</p>}  */}
    </div>
  );
}

export default Time;