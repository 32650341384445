import { Route, Routes } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import routes from './routes';
import Content400 from './components/pages/Error404/Content404';

function App() {

  const routeComponents = routes.map(({ path, component, meta }, key) => (
        <Route
          path={path}
          element={
            <MainLayout>
              <MainLayout.Content>
                {component}
              </MainLayout.Content>
            </MainLayout>
          }
          key={key}
        />
      )
  );

  return (
    <>
      <Routes>
        {routeComponents}
        <Route path="*" element={<Content400 />} />
      </Routes>
    </>
  );
}

export default App;
