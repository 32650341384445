import { useState, useEffect } from "react";

const useScriptsLoader = (scriptUrls = []) => {
  const [areScriptsLoaded, setAreScriptsLoaded] = useState(false);

  useEffect(() => {
    let isCancelled = false;

    // Fonction pour vérifier l'état de chargement d'un script donné
    const checkScriptLoadStatus = (src) => {
      const performanceEntries = performance.getEntriesByType("resource");

      // Cherche l'entrée du script correspondant à l'URL donnée
      const scriptEntry = performanceEntries.find(
        (entry) => {
          return entry.initiatorType === "script" && (entry.name === process.env.REACT_APP_LMS_COURSE_SERVICE /* "http://127.0.0.1:3001" */ + src || entry.name === src)
        }
      );

      // Vérifie si le script est complètement chargé (responseEnd > 0)
      return scriptEntry && scriptEntry.responseEnd > 0;
    };

    const checkAllScripts = () => {
      const allScriptsLoaded = scriptUrls.every((src) =>
        checkScriptLoadStatus(src)
      );
      if (!isCancelled) setAreScriptsLoaded(allScriptsLoaded);
    };

    // Observer pour détecter la fin du chargement des scripts
    const performanceObserver = new PerformanceObserver(() => {
      checkAllScripts();
    });

    performanceObserver.observe({ entryTypes: ["resource"] });

    // Vérifie les scripts à l'initialisation
    checkAllScripts();

    // Nettoyage à la désactivation du composant
    return () => {
      isCancelled = true;
      performanceObserver.disconnect();
    };
  }, [scriptUrls]);

  return areScriptsLoaded;
};

export default useScriptsLoader;
