import { useEffect } from 'react';

export const scriptsObserved = [
  `https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.4/jquery.min.js`, //`/assets/js/vendor/jquery.min.js`,
  `/assets/js/vendor/sal.min.js`, // `https://cdn.jsdelivr.net/npm/sal@1.0.0/index.min.js`, //
  `/assets/js/app-sal.js`,
];

const LoadScriptAfterReact = () => {
    useEffect(() => {
        const scriptUrls = [
          ...scriptsObserved,
          // `/assets/js/vendor/modernizr.min.js`, NOT USED
          `https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.0.2/js/bootstrap.min.js`, // `/assets/js/vendor/bootstrap.min.js`,
          // `/assets/js/vendor/jquery.waypoints.js`, NOT USED
          `/assets/js/vendor/backtotop.min.js`,
          `https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.2.0/jquery.magnific-popup.min.js`, // `/assets/js/vendor/magnifypopup.min.js`,
          // `/assets/js/vendor/jquery.countdown.min.js`, NOT USED
          // `/assets/js/vendor/jQuery.rProgressbar.min.js`, NOT USED
          // `/assets/js/vendor/easypie.js`, NOT USED
          // `/assets/js/vendor/odometer.min.js`, NOT USED
          // `/assets/js/vendor/isotop.min.js`, NOT USED
          // `/assets/js/vendor/imageloaded.min.js`, NOT USED
          //   `/assets/js/vendor/lightbox.min.js`, NOT USED
          `https://cdnjs.cloudflare.com/ajax/libs/parallax/3.1.0/parallax.min.js`, //`/assets/js/vendor/paralax.min.js`,
          // `/assets/js/vendor/paralax-scroll.min.js`, NOT USED
          //   `/assets/js/vendor/jquery-ui.min.js`, NOT USED
          `https://cdnjs.cloudflare.com/ajax/libs/Swiper/8.0.7/swiper-bundle.min.js`, //`/assets/js/vendor/swiper-bundle.min.js`,
          `/assets/js/vendor/svg-inject.min.js`, // `https://cdnjs.cloudflare.com/ajax/libs/svg-injector/1.1.2/svg-injector.min.js`, //
          `https://cdnjs.cloudflare.com/ajax/libs/vivus/0.4.6/vivus.min.js`, //`/assets/js/vendor/vivus.min.js`,
          // `/assets/js/vendor/tipped.min.js`, NOT USED
          `/assets/js/vendor/smooth-scroll.min.js`, // `https://cdnjs.cloudflare.com/ajax/libs/smooth-scroll/8.0.0/js/smooth-scroll.js`, //
          //   `/assets/js/vendor/isInViewport.jquery.min.js`, NOT USED
          `/assets/js/app.js`,
        ];

        scriptUrls.forEach((url) => {
            const script = document.createElement('script');
            script.src = url;
            script.async = false;
            // script.crossorigin = "anonymous";
            // script.referrerpolicy = "no-referrer";
            document.body.appendChild(script);
        });

        return () => {
            scriptUrls.forEach((url) => {
                const script = document.querySelector(`script[src="${url}"]`);
                if (script) {
                    document.body.removeChild(script);
                }
            });
        };
    }, []);

    return null; 
};

export default LoadScriptAfterReact;
